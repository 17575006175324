body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* add building use css ===================== */

.add-user-button-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
}

.add-user-form {
  display: flex;
  width: 100% !important;
  flex-direction: column;
  margin: 2rem 0;
  gap: 2rem;
}

/* ================================ */
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24 {
  height: max-content !important;
}

.dialog-addUser .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24 {
  width: 30rem !important;
  height: max-content !important;
}

.dialog-userImg .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24 {
  width: 30rem !important;
  height: max-content !important;
}

.delete-confirmation .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24 {
  max-width: 30rem !important;
  height: max-content !important;
}

.dialog-userImg .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24 img {
  width: 100% !important;
  height: auto !important;
}

.face-detect-back-button {
  background-color: #e1e1e1 !important;
  color: gray !important;
}

.no-record-class {
  font-weight: 400 !important;
  font-size: 1rem !important;
}

.no-record-wrapper {
  position: relative;
  padding-top: 16px;
  right: calc(-250% + 16px);
  padding-left: 16px;
}

.img-file .MuiInputLabel-outlined {
  display: none !important;
}

.img-file .MuiInputLabel-outlined.MuiInputLabel-shrink {
  display: block !important;
}

.attendance-header {
  display: flex;
  justify-content: start;
  align-items: flex-end;
  margin: 1rem 0;
  gap: 1rem;
}

.attendance-user input {
  width: auto !important;
}

.building-table-button-wrapper button {
  margin-left: 0 !important;
}

.css-rnmm7m-MuiPaper-root-MuiDialog-paper,
.rozcomapp-custom-modal .MuiDialog-container .MuiPaper-root,
.rozcomapp-custom-modal .MuiDialog-container .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24 {
  max-width: 650px !important;
  padding-left: 20px;
  padding-right: 20px;
}